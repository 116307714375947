import React from 'react'
import { motion, transform } from "framer-motion";
import '../../styles/introStyles.css'

export default function Intro() {
  const wrapperVariants = {
    initial: {
      border: "2px solid rgba(7, 139, 255, 1)",
      padding: "0px",
    },
    animate: {
      border: "2px solid rgba(7, 139, 255, 0)",
      padding: "15px",
      transition: {
        duration: 1,
        // ease: "easeInOut",
        repeat: Infinity,
        // repeatType: "reverse",
      },
    }
  };

  const profileCoverVariants = {
    initial: {
        margin: '15px',
    },
    animate: {
        margin: '0px',
        transition:{
            duration: 1,
            // ease: "easeInOut",
            repeat: Infinity,
            // repeatType: "reverse",
        }
    }
  }

  return (
    <div className="intro-wrapper">
      <div className='intro'>
        <div className="hello-section">
          👋Hey there, I am Manas Srivastava
        </div>
        <div className="highlight-intro">
          <h1> Full Stack Developer </h1>
        </div>
        <div className="extra-intro">
          I am a Full Stack Developer with a passion for creating beautiful and functional websites.
        </div>
      </div>

      <div className="profile">
      <motion.div className="profile-pic-outer-cover" variants={profileCoverVariants} initial="initial" animate="animate">
        <motion.div className="profile-pic-wrapper" variants={wrapperVariants} initial="initial" animate="animate">
          <div className="profile-pic-border">
            <img className="profile-pic" src='/professional.png' />
          </div>
        </motion.div>
      </motion.div>
    </div>

    </div>
  )
}
